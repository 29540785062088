import React from 'react'

import ServicesNode from 'components/content/services-node'
import data from 'data/services'
import styles from './services-overview.module.css'

const ServicesOverview = () => {
  const nodes = data.map((node, index) => <ServicesNode key={index} indicator={index} {...node} />)

  return (
    <section className={styles.container}>
      { nodes }
    </section>
  )
}

export default ServicesOverview