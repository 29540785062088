import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AnimatedButton from 'components/buttons/animated-button'
import styles from './services-node.module.css'

const AccentBlock = () => <span className={styles.accent}></span>

const ServicesNode = ({ indicator, name, content, icon, weight, to }) => {
  let count = indicator + 1
  count = count.toString().padStart(2, '0')

  return (
    <section className={styles.node}>
      <span className={styles.indicator}>{ count }</span>

      <div className={styles.titleContainer}>
        <FontAwesomeIcon icon={[weight, icon]} className={styles.icon} />
        <h3 className={styles.title}>{ name }</h3>
      </div>

      <AccentBlock />

      <p className={styles.content} dangerouslySetInnerHTML={{ __html: content }}></p>
    
      <AnimatedButton to={to} message={'Learn More'} />
    </section>
  )
}

export default ServicesNode