export default [
  {
    name: 'Business Strategy & Integration',
    icon: 'handshake',
    weight: 'fal',
    content: 'Under the traditional model of practice operation, the day-to-day responsibilities of the clinician leave little time for the development of a comprehensive, macro and micro-level business strategy. ADG develops tailored strategies for all of our affiliates and, most importantly, integrates those strategies with practice operations and marketing to ensure continuity and successful execution.',
    to: '/what-we-do/business-strategy-integration'
  },{
    name: 'Tailored Marketing & Advertising',
    icon: 'megaphone',
    weight: 'fal',
    content: 'Most dental offices take a cursory, and uncoordinated, approach to marketing. Our approach to marketing is tailored, comprehensive, and, most importantly, consistent. Our team provides extensive support related to marketing matters including; but, not limited to: practice branding, differentiation, internal & external marketing, reputation management, and website development.',
    to: '/what-we-do/tailored-marketing-advertising'
  }, {
    name: 'Human Resource Management',
    icon: 'books',
    weight: 'fal',
    content: 'Critical to the successful running of an efficient practice is a clear set of office policies, and the effective management of employees. Given the close proximity in which the clinician and staff work with one another, it is not uncommon for hierarchical lines to become blurred, placing the clinician in the difficult position of straddling the line between ‘boss’ and ‘friend.’ This makes effective management increasingly...',
    to: '/what-we-do/human-resource-management'
  }, {
    name: 'Operations Optimization',
    icon: 'analytics',
    weight: 'fal',
    content: 'As is the case with business strategy and integration, the traditional practice model leaves little opportunity to focus on effective operations strategy. As a result, the vast majority of dental practices operate inefficiently, and inconsistently.<br /><br />Informed by decades of experience in dental services, ADG provides its affiliate practices the resources, education...',
    to: '/what-we-do/operations-optimization'
  }
]