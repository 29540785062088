import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import MobileDetect from 'mobile-detect'
import classnames from 'classnames'

// Import all SVG shapes
import AnimatedButton from 'components/buttons/animated-button'
import addAnimation from 'components/content/animation'
import styles from './primary-hero.module.css'

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        fluid(maxHeight: 1075, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    backgroundImage: file(relativePath: { eq: "hex-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Title = ({ children }) => <h1>{ children }</h1>
const AnimatedTitle = addAnimation(Title)

const PrimaryHero = () => {
  const data = useStaticQuery(query);
  const image = data.placeholderImage.childImageSharp.fluid;
  const backgroundImage = data.backgroundImage.childImageSharp.fluid;
  const userAgent = typeof window !== 'undefined' ?  window.navigator.userAgent.toString() : ''
  const md = new MobileDetect(userAgent)

  return (
    <section className={styles.container}>
      <div className={styles.shapeContainer}>
        <div className={styles.shape1}></div>
        <div className={styles.shape2}></div>
        <div className={styles.shape3}></div>
        <div className={styles.shape4}></div>
      </div>

      <div className={styles.canvasBackground}>
        <Img fluid={backgroundImage} className={styles.backgroundImage}/>
      </div>

      <Img fluid={image} imgStyle={{ height: '100vh', width: 'auto' }} className={classnames(styles.heroImage, md.tablet() && styles.isTablet)} />

      <div className={styles.canvas}>
        <div className={styles.contentBorder}></div>
        <AnimatedTitle>Dental Support. Redefined.</AnimatedTitle>
        <p className={styles.content}>
          <span className={styles.accent}></span>
          { 'Integrity. Collaboration. Commitment.' }
        </p>
        {/* <AnimatedButton message={'Our Movement'} light to='/process' /> */}
      </div>
    </section>
  )
}

export default PrimaryHero