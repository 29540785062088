/* eslint-disable */

import React, { useRef, useState, useEffect } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

const addAnimation = (Component) => {
  return ({ ...props }) => {
    const [visible, setVisibility] = useState(true)
    const [enabled, setEnabled] = useState(false)
    const [animationComplete, setAnimComplete] = useState(false)
    const componentRef = useRef(null)

    useEffect(() => {
      setEnabled(true)

      if (visible) {
        animate()
      }
    }, [])

    const animate = () => {
      
    }

    return (
      <VisibilitySensor>
        {
          animationComplete
          ? <Component {...props} />
          : <span ref={componentRef}
            style={{ position: 'relative', overflow: 'hidden', width: '100%' }}
          >
              <Component {...props} />
            </span>
            
        }
      </VisibilitySensor>
    )
  }
}

export default addAnimation