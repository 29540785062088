import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Modal, List } from 'semantic-ui-react'

import SEO from 'components/seo'
import PrimaryHero from 'components/hero/primary-hero'
import Footer from 'components/footer/footer'
import ServicesOverview from 'components/content/services-overview'
import Testimonials from 'components/testimonials'
import { createLayout } from 'components/layouts'

class IndexPage extends Component {
  state = {
    modalOpen: false
  }

  componentDidMount() {
    this.handleOpen()
  }

  handleOpen = () => this.setState({ modalOpen: true })

  render() {
    const currentPage = this.props.data.wordpressPage
    const acf = currentPage.acf.content_layout_page
    const { primary_hero } = acf

    let flexibleContent = null

    if (acf) {
      flexibleContent = createLayout(acf)
    }

    return (
      <>
        <SEO title="Home" yoast={currentPage.yoast_meta && currentPage.yoast_meta} />
        {/*<Modal 
          defaultOpen
          closeIcon
        >
          <Modal.Header>COVID-19 Update</Modal.Header>
          <Modal.Content>
            <p>At Array Dental Group, the health and safety of our employees, affiliates, and their patients is our top priority. Our senior leadership team is proactively engaged on a daily basis, and is working diligently, to effectively manage the rapidly evolving situation surrounding the Coronavirus (COVID-19). We will continue to monitor media reports for updated guidance provided by authorities, and we will take any action necessary to protect our employees, affiliates, and their patients.</p>
            <br/>
            <p>To that end, we are taking proactive precautions to minimize risk of exposure in our corporate office, and all affiliated practices, by strictly adhering to the Centers for Disease Control and Prevention (CDC) protocols for preventing the spread of airborne and bloodborne pathogens (such as bacteria, fungi, and viruses).</p>
            <br/>
            <p>For additional information, and the latest updates on COVID-19, please refer to the links below:</p>
            <List>
              <List.Item as='a' href='https://www.cdc.gov/coronavirus/2019-nCoV/index.html' target='_blank'>https://www.cdc.gov/coronavirus/2019-nCoV/index.html</List.Item>
              <List.Item as='a' href='https://www.whitehouse.gov/wp-content/uploads/2020/03/03.16.20_coronavirus-guidance_8.5x11_315PM.pdf' target='_blank'>https://www.whitehouse.gov/wp-content/uploads/2020/03/03.16.20_coronavirus-guidance_8.5x11_315PM.pdf</List.Item>
              <List.Item as='a' href='https://www.cdc.gov/oralhealth/infectioncontrol/summary-infection-prevention-practices/index.html' target='_blank'>https://www.cdc.gov/oralhealth/infectioncontrol/summary-infection-prevention-practices/index.html</List.Item>
              <List.Item as='a' href='https://www.cdc.gov/oralhealth/infectioncontrol/statement-COVID.html' target='_blank'>https://www.cdc.gov/oralhealth/infectioncontrol/statement-COVID.html</List.Item>
            </List>

            <p><strong><em>Click anywhere outside the popup to close.</em></strong></p>
          </Modal.Content>
        </Modal>*/}

        <PrimaryHero {...primary_hero} />
        { flexibleContent }
        <ServicesOverview />
        {/* <Testimonials /> */}
        <Footer />
      </>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "home" }) {
      id
      title
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_twitter_title
        yoast_wpseo_website_name
        yoast_wpseo_person_name
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_image
        yoast_wpseo_facebook_description
        yoast_wpseo_company_or_person
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_social_defaults {
          facebook_site
          instagram_url
          linkedin_url
          myspace_url
          og_default_image
          og_default_image_id
          og_frontpage_desc
          og_frontpage_image
          og_frontpage_image_id
          og_frontpage_title
          opengraph
          pinterest_url
          pinterestverify
          twitter
          twitter_card_type
          twitter_site
          wikipedia_url
          youtube_url
        }
      }
      acf {
        blurb
        custom_title
        primary_hero {
          headline
          subcontext
        }
        content_layout_page {
          __typename
          ... on WordPressAcf_call_to_action {
            id
            title
            title_emphasis
            content
            page_link {
              target
              title
              url
            }
            cta_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          ... on WordPressAcf_bordered_content {
            id
            bottom_border
            content
          }
          ... on WordPressAcf_bordered_content_with_button {
            id
            bottom_border
            content
            page_link {
              target
              title
              url
            }
          }
          ... on WordPressAcf_borderless_content {
            id
            content
            subtitle
            title
            two_columns
          }
          ... on WordPressAcf_contact_form {
            id
            content
          }
          ... on WordPressAcf_image_collage {
            id
            alternative_arrangement
            image_gallery {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on WordPressAcf_service_list {
            id
            services {
              content
              icon
              title
            }
          }
          ... on WordPressAcf_team_members {
            id
            team {
              name
              picture {
                wordpress_id
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 95) {
                      base64
                      aspectRatio
                      src
                      srcSet
                      sizes
                      originalImg
                      originalName
                      presentationWidth
                      presentationHeight
                    }
                  }
                }
              }
              title
            }
            title
          }
          ... on WordPressAcf_giant_button {
            id
            page_link {
              target
              title
              url
            }
            title
            subtitle
          }
          ... on WordPressAcf_horizontal_divider {
            id
            color
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
